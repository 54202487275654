<template>
  <div class="detail-school wrapper">
    <div class="school-content">
      <div class="content-top">
        <img class="top-img" :src="info.cover_image" />
        <div class="top-nav">
          <i class="nav-icon comeBack" @click="comeBack"></i>
          <i class="nav-icon share rig"></i>
          <i class="nav-icon collect rig"></i>
        </div>
        <div class="top-rank">
          <div class="rank-list">
            <i class="list-icon">{{info.shanghai_ranks ? info.shanghai_ranks : ''}}</i>
            <span class="list-text">ARWU Rankings</span>
          </div>
          <div class="rank-list">
            <i class="list-icon">{{info.qs_ranks ? info.qs_ranks : ''}}</i>
            <span class="list-text">QS World University Rankings</span>
          </div>
        </div>
      </div>
      <div class="content-item">
        <div class="item-info">
          <img class="info-logo" :src="info.logo" />
          <div class="info-box">
            <p class="info-title">{{info.en_name}}</p>
            <div class="info-labels">
              <span class="lab-self" v-for="(item, index) in info.labels" :key="index">{{item}}</span>
            </div>
            <p class="info-text">City: {{info.city}}, {{info.province}}</p>
            <p class="info-text">Type: {{info.school_attribute}}</p>
          </div>
          <div class="item-video" v-if="info.video.length > 0">
            <nut-video :sources="[{ src: info.video[0].video, type: 'video/mp4'}]" :options="options"> </nut-video>
          </div>
        </div>
      </div>
      <div class="content-nav" v-if="info.is_product_show === 0">
        <span class="nav-list" :class="[{'active': item.type === activeType}]" v-for="(item, index) in nav" :key="index" @click="clickActive(item.type)">{{item.title}}</span>
      </div>
      <div class="content-nav" v-if="info.is_product_show === 1">
        <span class="nav-list te" :class="[{'active': item.type === activeType}]" v-for="(item, index) in navs" :key="index" @click="clickActive(item.type)">{{item.title}}</span>
      </div>
      <div class="content-info" v-if="activeType === '1'">
        <div class="info-list">
          <p class="list-title">Basic information</p>
          <p class="list-text"><span class="text-lf">City</span><span class="text-rig">{{info.city}}, {{info.province}}</span></p>
          <p class="list-text"><span class="text-lf">Founded in</span><span class="text-rig">{{info.built_at}}</span></p>
          <p class="list-text"><span class="text-lf">Type</span><span class="text-rig">{{info.school_attribute}}</span></p>
          <p class="list-text"><span class="text-lf">Number of total students</span><span class="text-rig">{{info.student_amount}}</span></p>
          <p class="list-text"><span class="text-lf">Number of international students</span><span class="text-rig">{{info.international_student_amount}}</span></p>
          <p class="list-text"><span class="text-lf">Number of faculty</span><span class="text-rig">{{info.faculty_amount}}</span></p>
        </div>
        <div class="info-list">
          <p class="list-title">About {{info.en_name}}</p>
          <p class="list-html" v-html="info.en_profile"></p>
        </div>
      </div>
      <div class="content-info" v-if="activeType === '2'">
        <div class="goods-item" v-if="info.selfFinancedProduct.length > 0">
          <div class="goods-item-list" v-for="(item, index) in info.selfFinancedProduct" :key="index" @click="goPage(item)">
            <div class="list-img">
              <img class="img-self" :src="item.cover_image" />
              <span class="goods-issp" v-if="item.issp === 1">ISSP</span>
            </div>
            <div class="list-info">
              <p class="info-title">{{item.en_name}}</p>
              <p class="info-text"><span class="text-color">Tuition: {{item.is_self === 1 ? item.tuition_last_fee : item.self_tuition_fee}}</span>({{item.is_self === 1 ? item.tuition_last_fee_unit : item.self_tuition_fee_unit}})</p>
              <p class="info-text">City: {{item.city}} {{item.province}}</p>
              <p class="info-text">Degree: {{item.education}}</p>
              <p class="info-text">Teaching language: {{item.language}}</p>
              <p class="info-text"><span class="text-btn" >Apply</span></p>
            </div>
          </div>
        </div>
      </div>
      <div class="content-info" v-if="activeType === '3'">
        <p class="info-html" v-html="info.advantage"></p>
      </div>
      <div class="content-info" v-if="activeType === '4'">
        <div class="info-list" v-for="(item, index) in info.albums" :key="index">
          <p class="list-title">{{index}}</p>
          <nut-swiper :paginationVisible="true" direction="horizontal" :loop="true" ref="demo1">
            <div v-for="(list, idx) in item" :key="idx" class="nut-swiper-slide">
              <img class="top-img" :src="list" />
            </div>
          </nut-swiper>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api.js'
export default {
  name: 'schoolDetail',
  props: {},
  data () {
    return {
      urlname: '',
      info: {},
      options: {
        autoplay: true,
        controls: true
      },
      activeType: '1',
      nav: [
        {
          title: 'Introduction',
          type: '1'
        },
        {
          title: 'Programs',
          type: '2'
        },
        {
          title: 'Advantages',
          type: '3'
        },
        {
          title: 'Albums',
          type: '4'
        }
      ],
      navs: [
        {
          title: 'Introduction',
          type: '1'
        },
        {
          title: 'Advantages',
          type: '3'
        },
        {
          title: 'Albums',
          type: '4'
        }
      ]
    }
  },
  components: {},
  computed: {},
  created () {
    this.urlname = this.$route.query.university ? this.$route.query.university : ''
    if (!this.urlname) {
      return false
    }
    this.getSchoolInfo() 
  },
  watch: {},
  methods: {
    comeBack () {
      this.$router.go(-1)
    },
    clickActive (type) {
      this.activeType = type
    },
    // 获取学校详情
    getSchoolInfo () {
      if (!this.urlname) {
        return false
      }
      let that = this
      Api.get({
        url: 'schools/'+ that.urlname + '?include=selfFinancedProduct',
        data: {},
        success (res) {
          if (res.data && Number(res.code) === 200) {
            that.info = res.data ? res.data : {}
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.top-img{height:242px;}
.top-rank{position:absolute;left:50%;bottom:60px;overflow:hidden;transform: translate(-50%,0);width:80%;}
.top-rank .rank-list{float:left;width:50%;text-align: center;color:#fff;}
.top-rank .rank-list .list-icon{display: block;width:50px;height:50px;background:url("~@/assets/images/school-detail-top.png") center no-repeat;background-size:50px;line-height:50px;margin:0 auto 10px;}
.top-rank .rank-list .list-text{font-size:12px;}
.content-item{margin-top:-10px;}
.content-item .item-info{background-color:#fff;border-radius:12px;margin:0 12px 10px;padding:15px 12px;position:relative;z-index:100;overflow:hidden;}
.content-item .item-info .info-logo{display:block;width:65px;float:left;}
.content-item .item-info .info-box{margin-left:76px;}
.content-item .item-info .info-title{font-size:15px;line-height:20px;max-height:40px;color:#222;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;word-break: break-all;margin-bottom:6px;}
.content-item .item-info .info-labels{margin-bottom:6px;}
.content-item .item-info .info-labels .lab-self{padding:0 5px;background-color:#eee7fb;color:#7c53c9;margin-right:5px;font-size:12px;border-radius:5px;}
.content-item .item-info .info-text{margin-bottom:6px;color:#999;font-size:12px;line-height:16px;}
.content-item .item-video{margin-top:12px;border-radius:12px;overflow:hidden;}
.content-nav{overflow:hidden;padding:20px 12px;}
.content-nav .nav-list{float:left;width:25%;text-align:center;color:#333;font-size:14px;line-height:20px;}
.content-nav .nav-list.te{width:33.33%;}
.content-nav .nav-list.active{font-weight:600;position:relative;}
.content-nav .nav-list.active::before{position:absolute;content:'';bottom:-6px;left:50%;width:15px;height:4px;background-color:#7c53c9;border-radius:4px;transform:translate(-50%,0);}
.content-info{padding:0 12px 12px;}
.content-info .info-list{border-radius:12px;background-color:#fff;padding:0 12px;margin-bottom:12px;}
.content-info .info-list .list-title{padding:12px 0;font-size:15px;color:#333;font-weight:bold;line-height:21px;border-bottom:1px solid #eee;}
.content-info .info-list .list-text{overflow:hidden;font-size:13px;color:#666;line-height:50px;}
.content-info .info-list .list-text .text-rig{float:right;color:#333;}
.content-info .info-list .list-html{padding:12px;}
.content-info .info-html{border-radius:12px;padding:12px;background-color:#fff;}
</style>
