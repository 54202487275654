<template>
  <div class="high-success">
    <div class="success-content">
      <div class="content-imgs">
        <img class="imgs-self" :src="adspaces.img1" />
        <img class="imgs-self" :src="adspaces.img2" />
      </div>
      <nut-infiniteloading @loadmore="onInfinite" :is-show-mod="true" :has-more="isHasMore" :is-loading="isLoading" loading-txt="Loading" unload-more-txt="no more information" :threshold="200">
        <div class="goods-item">
          <div class="goods-item-list" v-for="(item, index) in list" :key="index" @click="goPage(item)">
            <div class="list-img">
              <img class="img-self" :src="item.cover_image" />
              <span class="goods-issp" v-if="item.issp === 1">ISSP</span>
            </div>
            <div class="list-info">
              <p class="info-title">{{item.en_name}}</p>
              <p class="info-text"><span class="text-color">Tuition: {{item.is_self === 1 ? item.tuition_last_fee : item.self_tuition_fee}}</span>({{item.is_self === 1 ? item.tuition_last_fee_unit : item.self_tuition_fee_unit}})</p>
              <p class="info-text">City: {{item.city}} {{item.province}}</p>
              <p class="info-text">Degree: {{item.education}}</p>
              <p class="info-text">Teaching language: {{item.language}}</p>
              <p class="info-text"><span class="text-btn" >Apply</span></p>
            </div>
          </div>
        </div>
      </nut-infiniteloading>
    </div>
  </div>
</template>

<script>
export default {
  name: 'highSuccess',
  props: {
    list: {
      type: Array,
      default: function () { return [] }
    },
    adspaces: {
      type: Object,
      default: function () { return {} }
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isHasMore: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
    }
  },
  components: {},
  computed: {},
  created () {},
  watch: {},
  methods: {
    goPage (item) {
      this.$router.push({
        path: '/detail/goods',
        query: {
          program: item.urlname,
          ProgramID: item.product_sn
        }
      })
    },
    onInfinite () {
      this.$emit('onInfinite')
    }
  }
}
</script>

<style scoped>
.content-imgs{overflow:hidden;margin-bottom:12px;}
.content-imgs .imgs-self{display: block;width:50%;box-sizing:border-box;float:left;padding-right:5px;border-radius:12px;}
.content-imgs .imgs-self:last-child{padding-left:5px;padding-right:0;}
</style>
