<template>
  <div class="best-seller">
    <div class="centent-banner">
      <swiper :options="swiperOption" ref="mySwiper" style="width:100%;height:115px;" v-if="bannerList.length">
        <swiper-slide class="swiper-slide swiper-container-3d" v-for="(banner, index) in bannerList" :key="index">
          <img :src="banner.image" style="width:100%;height:100%;display:block;border-radius:10px" @click="bannerPage(banner.url)">
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="centent-nav">
      <div class="nav-item">
        <div class="nav-item-list" v-for="(item, index) in navList" :key="index" @click="goPage(item.url, item.type)">
          <i class="item-list-icon" :class="item.title"></i>
          <p class="item-list-text">{{item.text}}</p>
        </div>
      </div>
    </div>
    <div class="centent-imgs">
      <img class="imgs-left" @click="goPage(leftUrl)" :src="leftImg">
      <div class="imgs-rig">
        <img class="rig-self" @click="goPage(rigTopUrl)" :src="rigTop" >
        <img class="rig-self" @click="goPage(rigBotUrl)" :src="rigBot" >
      </div>
    </div>
    <div class="centent-goods">
      <p class="goods-title">Recommended by EDUPRCHINA <span class="title-more" @click="hint">More</span></p>
      <div class="goods-item">
        <div class="goods-item-list" v-for="(item, index) in goodsList" :key="index" @click="goDetail(item, 'goods')">
          <div class="list-img">
            <img class="img-self" :src="item.cover_image" />
            <span class="goods-issp" v-if="item.issp === 1">ISSP</span>
            <!-- <p class="goods-id">ID: {{item.product_sn}}</p> -->
          </div>
          <div class="list-info">
            <p class="info-title">{{item.en_name}}</p>
            <p class="info-text"><span class="text-color">Tuition: {{item.is_self === 1 ? item.tuition_last_fee : item.self_tuition_fee}}</span>({{item.is_self === 1 ? item.tuition_last_fee_unit : item.self_tuition_fee_unit}})</p>
            <p class="info-text">City: {{item.city}} {{item.province}}</p>
            <p class="info-text">Degree: {{item.education}}</p>
            <p class="info-text">Teaching language: {{item.language}}</p>
            <p class="info-text"><span class="text-btn">Apply</span></p>
          </div>
        </div>
      </div>
    </div>
    <div class="centent-goods">
      <p class="goods-title">Partner universities & colleges</p>
      <div class="school-box">
        <div class="school-item-list" v-for="(item, index) in schoolsList" :key="index" @click="goDetail(item, 'school')">
          <div class="list-box">
            <div class="list-img">
              <img class="img-self" :src="item.home_image">
              <div class="img-teb">
                <span class="teb-self" v-for="(info, idx) in item.labels" :key="idx">{{info}}</span>
              </div>
            </div>
            <div class="list-info">
              <p class="info-title">{{item.en_name}}</p>
              <p class="info-text"><span class="text-more">More</span>City: {{item.city}} {{item.province}} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api.js'
export default {
  name: 'bestSeller',
  props: {
    bannerList: {
      type: Array,
      default: function () { return [] }
    }
  },
  data () {
    return {
      swiperOption: {
        loop: true, //是否循环轮播
        pagination: {
          el: ".swiper-pagination",
          type: 'bullets',
          clickable: true,
          dynamicBullets: false
        },
        speed: 1000, //切换速度
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        //自动轮播
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        //设置slider容器能够同时显示的slides数量
        slidesPerView: 1
      },
      navList: [
        {
          title: 'scholarships',
          text: 'Scholarships',
          url: 'goodsList',
          type: 1
        },
        {
          title: 'selfFinanced',
          text: 'Self-financed',
          url: 'goodsList',
          type: 2
        },
        {
          title: 'universities',
          text: 'Universities',
          url: 'universitiesList'
        },
        {
          title: 'rankings',
          text: 'Rankings',
          url: '/pages/index/rankings/index',
          type: 'rankings'
        }
      ],
      goodsList: [],
      schoolsList: [],
      leftImg: '',
      leftUrl: '',
      rigTop: '',
      rigTopUrl: '',
      rigBot: '',
      rigBotUrl: ''
    }
  },
  components: {},
  computed: {},
  created () {
    this.recommend()
    this.getSchools()
    this.adspaces()
  },
  watch: {},
  methods: {
    goPage (url, text) {
      if (text === 'rankings') {
        this.hint()
        return false
      }
      this.$router.push({
        name: url,
        params: {
          type: text
        }
      })
    },
    goDetail (item, text) {
      let url = ''
      let params = {}
      if (text === 'goods') {
        url ='/detail/goods'
        params = {
          program: item.urlname,
          ProgramID: item.product_sn
        }
      } else {
        url = '/detail/school'
        params = {
          university: item.urlname
        }
      }
      this.$router.push({
        path: url,
        query: params
      })
    },
    // recommend
    recommend () {
      let that = this
      Api.post({
        url: 'products/recommend',
        data: {
          is_edu: 1,
          page: 1,
          label_id: 0
        },
        success (res) {
          if (res.code === 200 && res.data) {
            that.goodsList = res.data
          }
        }
      })
    },
    // 获取学校列表
    getSchools () {
      let that = this
      Api.get({
        url: 'schools?limit=8&home_state=1&page=1',
        data: {
        },
        success (res) {
          if (res.data && res.code === 200) {
            that.schoolsList = res.data
          }
        }
      })
    },
    bannerPage (url) {
      if (url !== '') {
        this.$router.push({
          path: url
        })
      }
    },
    hint () {
      this.$toast.warn('The mobile site is under maintenance, please open EDUPRCHINA website on the computer to use. If any questions, please contact your consultant or send email to service@eduprchina.com!')
    },
    adspaces () {
      let that = this
      Api.get({
        url: 'adspaces',
        data: {
          type: 1
        },
        success (res) {
          if (res.data && res.code === 200) {
            that.leftImg = res.data.img1 ? res.data.img1 : ''
            that.leftUrl = res.data.img1 ? res.data.url1 : ''
            that.rigTop = res.data.img1 ? res.data.img2 : ''
            that.rigTopUrl = res.data.img1 ? res.data.url2 : ''
            that.rigBot = res.data.img1 ? res.data.img3 : ''
            that.rigBotUrl = res.data.img1 ? res.data.url3 : ''
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.swiper-container{--swiper-theme-color:#7c53c9;}
.centent-nav .nav-item{overflow:hidden;margin-top:20px;}
.centent-nav .nav-item .nav-item-list{float:left;width:25%;font-size:12px;color:#222222;text-align:center;}
.centent-nav .nav-item .nav-item-list .item-list-icon{display:block;width:41px;height:41px;margin:0 auto 12px;background: url("~@/assets/images/index-nav.png") no-repeat;background-size:cover;background-position:0 0;}
.centent-nav .nav-item .nav-item-list .item-list-icon.selfFinanced{background-position:-41px 0;}
.centent-nav .nav-item .nav-item-list .item-list-icon.universities{background-position:-82px 0;}
.centent-nav .nav-item .nav-item-list .item-list-icon.rankings{background-position:-123px 0;}
.centent-imgs{margin-top:20px;overflow:hidden;}
.centent-imgs .imgs-left{float:left;display:block;width:50%;height:244px;margin-right:5px;border-radius:20px;}
.centent-imgs .imgs-rig{width:48%;float:left;}
.centent-imgs .imgs-rig .rig-self{display:block;height:120px;width:100%;margin-bottom:5px;border-radius:20px;}
.centent-goods{margin-top:20px;}
.centent-goods .goods-title{font-size:18px;font-weight:bold;line-height:32px;color:#333;overflow:hidden;}
.centent-goods .goods-title .title-more{float:right;font-size:12px;color:#7c53c9;font-weight:100;}
.centent-goods .goods-item .goods-item-list{overflow:hidden;padding:10px 0;border-bottom:4px solid #e8ddfb;}
.centent-goods .goods-item .goods-item-list .list-img{float:left;position:relative;}
.centent-goods .goods-item .goods-item-list .list-img .img-self{display: block;width:120px;height:120px;}
.centent-goods .goods-item .goods-item-list .list-img .goods-issp{position:absolute;padding:2px 6px;left:0;top:0;font-size:12px;background-color:#7c53c970;color:#fff;border-radius:0 12px 0 12px;}
.centent-goods .goods-item .goods-item-list .list-img .goods-id{position:absolute;left:0;bottom:0;padding:0 5px;font-size:12px;text-align:center;background-color:#e8ddfb;width:100%;line-height:22px;color:#fff;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;box-sizing:border-box;}
.centent-goods .goods-item .goods-item-list .list-info{margin-left:140px;}
.centent-goods .goods-item .goods-item-list .list-info .info-title{font-size:12px;color:#a1a1a1;line-height:18px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;word-break: break-all;margin-bottom:5px;}
.centent-goods .goods-item .goods-item-list .list-info .info-text{font-size:14px;color:#737373;margin-bottom:5px;}
.centent-goods .goods-item .goods-item-list .list-info .info-text .text-color{color:#ff6433;}
.centent-goods .goods-item .goods-item-list .list-info .info-text:last-child{margin-bottom:0;}
.centent-goods .goods-item .goods-item-list .list-info .info-text .text-btn{width:72px;display:block;background-color:#7c53c9;text-align: center;color: #fff;font-size:12px;line-height:18px;border-radius:18px;}
.school-box{overflow:hidden;}
.school-box .school-item-list{float:left;width:50%;padding-right:5px;box-sizing:border-box;margin-bottom:10px;}
.school-box .school-item-list:nth-child(2n){padding-left:5px;padding-right:0;}
.school-box .school-item-list .list-box{border:1px solid #e8ddfb;}
.school-box .school-item-list .list-img{position:relative;}
.school-box .school-item-list .list-img .img-self{display:block;width:100%;height:102px;}
.school-box .school-item-list .list-img .img-teb{position:absolute;top:0;left:0;overflow:hidden;}
.school-box .school-item-list .list-img .img-teb .teb-self{display:inline-block;padding:0 5px;line-height:18px;text-align: center;background-color: #7c53c970;color:#fff;font-size:12px;margin-right:5px;margin-bottom:5px;}
.school-box .school-item-list .list-info{padding:5px 10px;}
.school-box .school-item-list .list-info .info-title{font-size:12px;color:#333;height:28px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;word-break: break-all;margin-bottom:5px;}
.school-box .school-item-list .list-info .info-text{overflow:hidden;font-size:12px;color:#ccc;height:28px;}
.school-box .school-item-list .list-info .info-text .text-more{float:right;display:block;width:32px;line-height:16px;border:1px solid #7c53c9;color:#7c53c9;text-align:center;}
</style>
