<template>
  <div class="login-sign wrapper">
    <nut-navbar @on-click-back="back" :leftShow="true" :rightShow="false">Sign Up</nut-navbar>
    <div class="sign-box">
      <div class="public-item">
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Username</p>
          <nut-textinput  v-model="info.username" placeholder="Please enter your username" :clearBtn="true" @blur="onBlur" :disabled="false" :has-border="false"/>
        </div>
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Nationality</p>
          <!-- <div class="list-selected" @click="nationalityShow = true">
            <span class="title"><label v-if="nationalityText === ''">Please select your nationality</label><label>{{nationalityText}}</label></span>
            <nut-icon class="selected-option" color="#999" type="down"></nut-icon>
          </div>
          <nut-picker :is-visible="nationalityShow" :list-data="nationalityList" @confirm="confirm" @close="nationalityShow = false"></nut-picker> -->
          <el-select class="list-selected te-select" v-model="info.country_id" filterable placeholder="Please select your nationality">
            <el-option  class="selected-option" v-for="item in nationalityList" :key="item.label" :label="item.value" :value="item.label">
            </el-option>
          </el-select>
        </div>
        <div v-if="showVerification">
          <div class="item-list">
            <p class="list-title"><span class="title-icon">*</span>Email</p>
            <nut-textinput  v-model="info.email" placeholder="Please enter your email" :clearBtn="true" :disabled="false" :has-border="false"/>
          </div>
          <div class="item-list">
            <p class="list-title"><span class="title-icon">*</span>Phone <span class="title-switch" @click="showVerification = false">Switch email verification</span></p>
            <div class="">
              <vue-country-intl v-model="countryCode" @onChange="getAreaCode" schema="input" placeholder></vue-country-intl>
              <nut-textinput class="list-ipt" v-model="info.mobile" placeholder="Please enter your Phone no" :clearBtn="true" :disabled="false" :has-border="false"/>
              <span class="list-btn" @click="getPhoneCode">{{codeText}}</span>
            </div>
          </div>
        </div>
        <div v-if="!showVerification">
          <div class="item-list">
            <p class="list-title"><span class="title-icon">*</span>Phone</p>
            <nut-textinput  v-model="info.mobile" placeholder="Please enter your Phone no" :clearBtn="true" :disabled="false" :has-border="false"/>
          </div>
          <div class="item-list">
            <p class="list-title"><span class="title-icon">*</span>Email <span class="title-switch" @click="showVerification = true">Switch Phone verification</span></p>
            <div class="">
              <nut-textinput class="list-ipt" v-model="info.email" placeholder="Please enter your email" :clearBtn="true" :disabled="false" :has-border="false"/>
              <span class="list-btn" @click="getEmailCode('sign')">{{codeText}}</span>
            </div>
            <p class="list-te">If you don't receive the  verification code, please check if the email is in your junk folder or spam list. You can also contact our consultant to get help. Email: <a href="mailto:eduprchinacom@gmail.com">eduprchinacom@gmail.com</a> WeChat: eduprchina01</p>
          </div>
        </div>
        
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Verification code</p>
          <nut-textinput  v-model="info.code" placeholder="Please enter Verification code" :clearBtn="true" :disabled="false" :has-border="false"/>
        </div>
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Password</p>
          <nut-textinput  v-model="info.password" placeholder="Please enter you password" :clearBtn="true" :disabled="false" :has-border="false"/>
        </div>
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Where did you know us?</p>
          <div class="list-selected" @click="knowShow = true">
            <span class="title"><label v-if="knowText === ''">Please select</label><label>{{knowText}}</label></span>
            <nut-icon class="selected-option" color="#999" type="down"></nut-icon>
          </div>
          <nut-picker :is-visible="knowShow" :list-data="options" @confirm="knowConfirm" @close="knowShow = false"></nut-picker>
        </div>
      </div>
    </div>
    <div class="sign-bottom">
      <div class="bottom-box">
        <div class="box-checkbox">
          <nut-checkbox v-model="checked" size="small">I agree to the Terms of Use and Privacy Policy.</nut-checkbox>
        </div>
        <div class="bottom-btn" @click="signUp">Sign Up</div>
        <p class="bottom-text" @click="goLogin">Already have an account? Log in here</p>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api.js'
export default {
  name: 'loginSign',
  props: {},
  data () {
    return {
      codeText: 'Get Code',
      checked: true,
      disabled: false,
      showVerification: false,
      countryCode: '',
      info: {
        country_id: 0,
        // city: '',
        password: '',
        // password_confirmation: '',
        type : 1,
        email: '',
        username: '',
        mobile: '',
        code: '',
        know_from: '1',
        from: 2
      },
      options: [
        [{
          label: 6,
          value: 'Wechat'
        },
        {
          label: 11,
          value: 'Live Stream'
        },
        {
          label: 7,
          value: 'Friends & Teachers'
        },
        {
          label: 2,
          value: 'Facebook'
        },
        {
          label: 3,
          value: 'Twitter'
        },
        {
          label: 9,
          value: 'Instagram'
        },
        {
          label: 10,
          value: 'Youtube'
        },
        {
          label: 4,
          value: 'Google Search'
        },
        {
          label: 5,
          value: 'Bing Search'
        },
        {
          label: 1,
          value: 'EDUPRCHINA Website'
        },
        {
          label: 8,
          value: 'Others'
        }]
      ],
      changeInfo: {
        email: '',
        code: '',
        password: '',
        password_confirmation: ''
      },
      // cityList: [],
      nationalityList: [
        {
          label: 0,
          value: "Please select your nationality"
        }
      ],
      type: '',
      dialogVisible: false,
      showPolicy: false,
      uniqueUsername: true,
      nationalityText: '',
      nationalityShow: false,
      knowText: '',
      knowShow: false
    }
  },
  components: {},
  computed: {},
  created () {
    document.title = "EDUPRCHINA"
    this.type = this.$route.params.type ? this.$route.params.type : 'sign'
    this.getCountries()
  },
  watch: {},
  mounted () {
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    goHome () {
      this.setLoginPopup(true)
      this.$router.push({path: '/'})
    },
    confirm (e) {
      this.nationalityShow = false
      this.info.country_id = e[0].label
      this.nationalityText = e[0].value
    },
    knowConfirm (e) {
      this.knowShow = false
      this.info.know_from = e[0].label
      this.knowText = e[0].value
    },
    // 获取国家
    getCountries () {
      let that = this
      Api.get({
        url: 'countries',
        data: {},
        success (res) {
          if (Number(res.code) === 200 && res.data) {
            for (let i in res.data) {
              let data = {
                label: res.data[i].id,
                value: res.data[i].name
              }
              that.nationalityList.push(data)
            }
          }
        }
      })
    },
    // 表单验证
    verify () {
      if (!this.checked) {
        this.$toast.warn('Please check the registration agreement')
        return false
      }
      if (!this.uniqueUsername) {
        this.$toast.warn("The username has been used by others, please change it.")
        return false
      }
      if (this.info.username === '') {
        this.$toast.warn("Username can't be empty. Please enter it.")
        return false
      }
      if (this.info.country_id === 0) {
        this.$toast.warn("Nationality can't be empty. Please select your nationality.")
        return false
      }
      if (this.info.mobile === '') {
        this.$toast.warn("Phone can't be empty. Please enter your phone number.")
        return false
      }
      if (this.info.email === '') {
        this.$toast.warn("Email can't be empty. Please enter it.")
        return false
      }
      if (this.info.email.indexOf('@') < 0) {
        this.$toast.warn("Email format error!")
        return false
      }
      if (this.info.code === '') {
        this.$toast.warn('Please enter in code')
        return false
      }
      if (this.info.password === '') {
        this.$toast.warn("Password can't be empty. Please enter it.")
        return false
      }
      return true
    },
    // 获取验证码
    getEmailCode (type) {
      let email = ''
      if (type === 'sign') {
        if (this.info.email === '') {
          this.$toast.warn('Please enter in email')
          return false
        }
        email = this.info.email
      } else if (type === 'password') {
        if (this.changeInfo.email === '') {
          this.$toast.warn('Please enter in email')
          return false
        }
        email = this.changeInfo.email
      }
      if (email.indexOf('@') < 0) {
        this.$toast.warn("Email format error!")
        return false
      }
      let data = {}
      if (type === 'sign') {
        data = {
          email: email,
          is_register: 1
        }
      } else {
        data = {
          email: email
        }
      }
      let time = 60
      let that = this
      Api.post({
        url: 'randcode',
        data: data,
        success (res) {
          if (res.data.code === 200 && res.data) {
            that.$toast.success(res.data.msg)
            let timer = setInterval( function () {
              time--
              that.codeText = time + "s"
              that.disabled = true
              if ( time === 0 ) {
                clearInterval(timer)
                that.codeText = "Get Code"
                that.disabled = false
              }
            }, 1000)
          } else {
            that.$toast.warn(res.data.msg)
          }
        }
      })
    },
    // 获取区号
    getAreaCode (selected) {
      this.countryCode = selected.dialCode
    },
    // 获取验证码
    getPhoneCode () {
      if (this.countryCode === '') {
        this.$toast.warn('Please select the country/area code')
        return false
      }
      if (this.info.mobile === '') {
        this.$toast.warn('Please enter your phone number')
        return false
      }
      let data = {
        prenum: this.countryCode,
        mobile: this.info.mobile
      }
      let time = 60
      let that = this
      let timer = setInterval( function () {
        time--
        that.codeText = time + "s"
        that.disabled = true
        if ( time === 0 ) {
          clearInterval(timer)
          that.codeText = "Get Code"
          that.disabled = false
        }
      }, 1000)
      Api.post({
        url: 'mobileCode',
        data: data,
        success (res) {
          if (res.data.code === 1 && res.data) {
            that.$toast.success(res.data.msg)
          } else {
            that.$toast.warn(res.data.msg)
          }
        }
      })
    },
    // 注册
    signUp () {
      let that = this
      if (!this.verify()) {
        return false
      }
      Api.post({
        url: 'signup',
        data: that.info,
        success (res) {
          if (res.data.code === 200 && res.data) {
            that.$toast.success(res.data.msg)
            that.$router.push({path: '/login'})
          } else {
            that.$toast.fail(res.data.msg)
          }
        }
      })
    },
    // 重置密码验证
    passWordVerify () {
      if (this.changeInfo.email === '') {
        this.$toast.warn("Email can't be empty. Please enter it.")
        return false
      }
      if (this.changeInfo.code === '') {
        this.$toast.warn("Verification code can't be empty. Please enter it.")
        return false
      }
      if (this.changeInfo.password === '') {
        this.$toast.warn("Password can't be empty. Please enter it.")
        return false
      }
      if (this.changeInfo.password !== this.changeInfo.password_confirmation) {
        this.$toast.warn("Password and Confirm password are different. Please re-enter.")
        return false
      }
      return true
    },
    passWord () {
      let that = this
      if (!this.passWordVerify()) {
        return false
      }
      Api.post({
        url: 'password',
        data: this.changeInfo,
        success (res) {
          if (res.data && res.code === 200) {
            that.$router.go(-1)
            that.$toast.success(res.data.msg)
          }
        }
      })
    },
    handleClose () {
      this.dialogVisible = false
    },
    show () {
      this.showPolicy = true
    },
    onBlur () {
      let that = this
      if (that.info.username === '') {
        this.$toast.warn("Username can't be empty. Please enter it.")
        return false
      }
      Api.post({
        url: 'uniqueUsername',
        data: {
          username: that.info.username
        },
        success (res) {
          if (res.data && res.data.code === 200) {
            that.uniqueUsername = true
            that.$toast.success(res.data.msg)
          } else {
            that.uniqueUsername = false
            that.$toast.fail(res.data.msg)
          }
        }
      })
    },
    goLogin () {
      this.$router.push({name: 'login'})
    }
  }
}
</script>

<style scoped>
.login-sign>>>.nut-checkbox input:checked{background-color:#7c53c9;border-color:#7c53c9;}
.sign-box{background-color:#fff;padding:0 10px;box-sizing:border-box;}
.sign-bottom{height:150px;}
.box-checkbox{padding-top:15px;margin:0 auto;width:280px;}
.sign-bottom .bottom-box{position:fixed;bottom:0;height:130px;max-width:750px;width:100%;margin:0 auto;background-color:#fff;}
.sign-bottom .bottom-box .bottom-btn{margin:10px 20px;height:45px;line-height:45px;text-align:center;background-color:#7c53c9;color:#fff;border-radius:10px;}
.list-te{font-size:12px;}
.sign-bottom .bottom-text{text-align: center;margin-bottom:20px;color: #564363;font-size:14px;}
.public-item .item-list .list-selected.te-select{width:100%;background-color:#fff;box-sizing:border-box;padding:0;border-color:#fff;}
</style>
