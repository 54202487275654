<template>
  <div class="universities-index wrapper">
    <div class="goods-top">
      <div class="top-search">
        <i class="search-icon back" @click="comeBack"></i>
        <i class="search-icon filter" @click="show = true"></i>
        <div class="search-ipt">
          <nut-searchbar v-model="info.keyword" placeText="Program name / Program ID" :hasSearchButton="false" @submit="getList"></nut-searchbar>
        </div>
      </div>
    </div>
    <nut-popup v-model="show" lock-scroll round position="right" :style="{height: '100vh', width: '90%', 'box-sizing': 'border-box'}">
      <div class="popup-bos">
        <div class="popup-item">
          <div class="popup-list">
            <p class="list-title">University tags</p>
            <div class="list-box">
              <span class="list-select" :class="{'active':  Number(info.label_id) === Number(0)}" @click="setLabelsId(0)">All</span>
              <span class="list-select" :class="{'active': Number(info.label_id) === Number(item.id)}" v-for="(item, index) in labelList" :key="index" @click="setLabelsId(item.id)">{{item.name}}</span>
            </div>
          </div>
          <div class="popup-list">
            <p class="list-title">City</p>
            <div class="list-box">
              <span class="list-select" :class="{'active':  Number(info.city_id) === Number(0)}" @click="setCityId(0)">All</span>
              <span class="list-select" :class="{'active': Number(info.city_id) === Number(item.id)}" v-for="(item, index) in hotCityList" :key="index" @click="setCityId(item.id)">{{item.en_name}}</span>
            </div>
          </div>
        </div>
        <div class="popup-boox">
          <div class="popup-btm">
            <div class="btm-btn" @click="reset">Reset</div>
            <div class="btm-btn search" @click="getList">Search</div>
          </div>
        </div>
      </div>
    </nut-popup>
    <div class="school-item">
      <nut-infiniteloading @loadmore="onSchoolfinite" :is-show-mod="true" :has-more="isHasMore" :is-loading="isLoading" loading-txt="Loading" unload-more-txt="no more information" :threshold="100">
        <div class="school-item-list" v-for="(item, index) in schoolList" :key="index" @click="goPage(item)">
          <img class="list-img" :src="item.logo" />
          <div class="list-info">
            <p class="info-title">{{item.en_name}}</p>
            <div class="info-lab">
              <span class="lab-self" v-for="(info, idx) in item.labels" :key="idx">{{info}}</span>
            </div>
            <p class="info-text">City: {{item.city}}, {{item.province}}</p>
          </div>
        </div>
      </nut-infiniteloading>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api.js'
export default {
  name: 'universitiesList',
  props: {},
  data () {
    return {
      brief: [],
      labelList: [],
      // 城市
      cityList: [],
      // 热门城市
      hotCityList: [],
      // 收搜条件
      info: {
        label_id: '0',
        city_id: '0',
        keyword: ''
      },
      show: false,
      isLoading: false,
      isHasMore: true,
      total_pages: 0,
      page: 1,
      limit: 8,
      schoolList: []
    }
  },
  components: {},
  computed: {},
  created () {
    this.getHotCityList()
    this.labels()
    this.getSchools()
  },
  watch: {},
  methods: {
    comeBack () {
      this.$router.go(-1)
    },
    reset () {
      this.info = {
        label_id: '0',
        city_id: '0',
        keyword: ''
      }
      this.show = false
    },
    goPage (item) {
      this.$router.push({
        path: '/detail/school',
        query: {
          university: item.urlname
        }
      })
    },
    // 下拉加载
    onSchoolfinite () {
      this.page++
      if (this.page <= this.total_pages) {
        this.getSchools()
      } else {
        this.isHasMore = false
        return false
      }
    },
    // 获取热门城市
    getHotCityList () {
      let that = this
      Api.get({
        url:'cities',
        data: {
          is_hot: 1
        },
        success (res) {
          if (res.data && res.code === 200) {
            for (let i in res.data) {
              that.hotCityList.push(res.data[i])
            }
          }
        }
      })
    },
    // 获取学校标签
    labels () {
      let that = this
      Api.get({
        url: 'labels',
        data: {
          type: 0
        },
        success (res) {
          if (res.data && res.code === 200) {
            that.labelList = res.data
          }
        }
      })
    },
    // 选择城市
    setCityId (id) {
      this.info.city_id = id
      this.$emit('outputUniversityCondition', this.info)
    },
    setLabelsId (id) {
      this.info.label_id = id
      this.$emit('outputUniversityCondition', this.info)
    },
    getList () {
      this.schoolList = []
      this.page = 1
      this.getSchools()
    },
    // 获取学校搜索列表
    getSchools () {
      let that = this
      this.isLoading = true
      let datas = {}
        datas = {
          ...this.info,
          limit: this.limit,
          page: this.page
        }
      Api.get({
        url: 'schools',
        data: datas,
        success (res) {
          if (res.code === 200) {
            if (res.data.length <= 0) {
              that.schoolList = []
            }
            for (let i in res.data) {
              that.schoolList.push(res.data[i])
            }
            that.show = that.show ? false : false
            that.isLoading = false
            that.total_pages = res.total_pages
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.goods-top{background-color:#fff;padding:14px 24px;}
.goods-top .top-search{overflow:hidden;}
.goods-top .top-search .search-icon{display:block;width:24px;height:24px;float:left;background:url('~@/assets/images/come-back.png') center no-repeat;background-size:24px;margin-top:8px;}
.goods-top .top-search .search-icon.filter{float:right;background-image:url('~@/assets/images/list-filter.png');margin-left:10px;}

</style>
