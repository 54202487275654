<template>
  <div class="detail-goods wrapper">
    <div class="goods-content">
      <div class="content-top">
        <nut-swiper :paginationVisible="true" direction="horizontal" :loop="true" ref="demo1">
          <div v-for="(item, index) in goodsInfo.detail_image" :key="index" class="nut-swiper-slide">
            <img class="top-img" :src="item" />
          </div>
        </nut-swiper>
        <div class="top-nav">
          <i class="nav-icon comeBack" @click="comeBack"></i>
          <i class="nav-icon share rig"></i>
          <i class="nav-icon collect rig"></i>
        </div>
      </div>
      <div class="content-item">
        <div class="item-info">
          <p class="info-title">{{goodsInfo.en_name}}</p>
          <div class="info-tag">
            <p class="tag-city">City: {{goodsInfo.city}}, {{goodsInfo.province}}</p>
            <p class="tag-city">Program ID: {{goodsInfo.product_sn}}</p>
          </div>
          <p class="info-text" v-if="Number(goodsInfo.is_self) === 1">Tuition fee: <span class="pay-text">{{goodsInfo.tuition_last_fee}}</span>  <span class="pay-past">{{goodsInfo.tuition_fee}}</span></p>
          <p class="info-text" v-else>Tuition fee: <span class="pay-text"> {{goodsInfo.self_tuition_fee}}</span></p>
          <p class="info-text">Application fee: <span class="pay-text"> {{goodsInfo.apply_fee}}</span></p>
          <p class="info-text">Service fee: <span class="pay-text"> {{goodsInfo.service_fee}}</span></p>
        </div>
        <!-- University -->
        <div class="item-collapse" v-if="!goodsInfo.is_self">
          <nut-collapse v-model="universityName">
            <nut-collapse-item title="University/College Name" :name="1">
              <div class="school-item">
                <div class="school-item-list">
                  <img class="list-img" :src="schools.logo" />
                  <div class="list-info">
                    <p class="info-title">{{schools.en_name}}</p>
                    <div class="info-lab">
                      <span class="lab-self" v-for="(info, idx) in schools.labels" :key="idx">{{info}}</span>
                    </div>
                    <p class="info-text">City: {{schools.city}}, {{schools.province}}</p>
                  </div>
                </div>
              </div>
            </nut-collapse-item>
          </nut-collapse>
        </div>
        <!-- Basic Information -->
        <div class="item-collapse">
          <nut-collapse v-model="BasicyName">
            <nut-collapse-item title="Basic information" :name="1">
              <div class="collapse-item">
                <p class="item-list">
                  <span class="list-title">Field</span>
                  <span class="list-text">{{goodsInfo.subject}}</span>
                </p>
                <p class="item-list">
                  <span class="list-title">Program name</span>
                  <span class="list-text">{{goodsInfo.en_name}}</span>
                </p>
                <p class="item-list">
                  <span class="list-title">Degree</span>
                  <span class="list-text">{{goodsInfo.education_duration}} of {{goodsInfo.education}} course</span>
                </p>
                <p class="item-list">
                  <span class="list-title">Intake</span>
                  <span class="list-text">{{goodsInfo.arrival_year}} {{goodsInfo.arrival_season}}</span>
                </p>
                <p class="item-list">
                  <span class="list-title">Application deadline</span>
                  <span class="list-text color">{{goodsInfo.end_at}}</span>
                </p>
              </div>
            </nut-collapse-item>
          </nut-collapse>
        </div>
        <!-- Scholarship information -->
        <div class="item-collapse" v-if="goodsInfo.is_self">
          <nut-collapse v-model="ScholarshipName">
            <nut-collapse-item title="Scholarship information" :name="1">
              <div class="collapse-item">
                <p class="item-list">
                  <span class="list-title">Scholarship type</span>
                  <span class="list-text">{{goodsInfo.scholarship}}</span>
                </p>
                <p class="item-list">
                  <span class="list-title">Scholarship duration</span>
                  <span class="list-text">{{goodsInfo.education_duration}}</span>
                </p>
                <p class="item-list">
                  <span class="list-title">Scholarship policy</span>
                </p>
                <p class="item-list" v-html="goodsInfo.scholarship_policy">
                </p>
              </div>
            </nut-collapse-item>
          </nut-collapse>
        </div>
        <!-- Applicant requirements -->
        <div class="item-collapse">
          <nut-collapse v-model="requirementsName">
            <nut-collapse-item title="Applicant requirements" :name="1">
              <div class="collapse-item">
                <p class="item-list" v-html="goodsInfo.specific_requirements"></p>
              </div>
            </nut-collapse-item>
          </nut-collapse>
        </div>
        <!-- Application documents -->
        <div class="item-collapse">
          <nut-collapse v-model="documentsName">
            <nut-collapse-item title="Application documents" :name="1">
              <div class="collapse-item">
                <p class="item-list" v-for="(item, index) in goodsInfo.requirement" :key="index">{{item.en_name ? item.en_name : ''}} {{item.memo ? '(' + item.memo + ')' : ''}}</p>
              </div>
            </nut-collapse-item>
          </nut-collapse>
        </div>
        <!-- Fee structure -->
        <div class="item-collapse">
          <nut-collapse v-model="structureName">
            <nut-collapse-item title="Fee structure" :name="1">
              <div class="collapse-item te">
                <p class="item-title">University Fees</p>
                <p class="item-list te" v-for="(item, index) in goodsInfo.school_fees" :key="index"><span class="list-title">{{item.name ? item.name : ''}}</span> <span class="list-text color">{{item.fee}}</span></p>
              </div>
               <div class="collapse-item te">
                <p class="item-title">EDUPRCHINA agent fees</p>
                <p class="item-list te" ><span class="list-title">Application fee (non-refundable)</span> <span class="list-text color">{{goodsInfo.apply_fee}}</span></p>
                <p class="item-list te" v-for="(item, index) in goodsInfo.other_fee" :key="index"><span class="list-title">{{item.name ? item.name : ''}}</span> <span class="list-text color">{{item.fee}}</span></p>
                <p class="item-list te" ><span class="list-title">Service fee</span> <span class="list-text color">Depend on agent level</span></p>
              </div>
            </nut-collapse-item>
          </nut-collapse>
        </div>
        <!-- Scholarship information -->
        <div class="item-collapse">
          <nut-collapse v-model="SpecialName">
            <nut-collapse-item title="Special notes" :name="1">
              <div class="collapse-item">
                <p class="item-list" v-html="goodsInfo.subsidy">
                </p>
              </div>
            </nut-collapse-item>
          </nut-collapse>
        </div>
      </div>
      <div class="content-recommend" v-if="recommend.length > 0">
        <p class="recommend-title">
          <span class="title-text">Recommended Programs</span>
        </p>
        <div class="goods-item">
          <div class="goods-item-list" v-for="(item, index) in recommend" :key="index" @click="goPage(item)">
            <div class="list-img">
              <img class="img-self" :src="item.cover_image" />
              <span class="goods-issp" v-if="item.issp === 1">ISSP</span>
            </div>
            <div class="list-info">
              <p class="info-title">{{item.en_name}}</p>
              <p class="info-text"><span class="text-color">Tuition: {{item.is_self === 1 ? item.tuition_last_fee : item.self_tuition_fee}}</span></p>
              <p class="info-text">City: {{item.city}} {{item.province}}</p>
              <p class="info-text">Degree: {{item.education}}</p>
              <p class="info-text">Teaching language: {{item.language}}</p>
              <p class="info-text"><span class="text-btn" >Apply</span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="goods-footer">
      <div class="footer-info">
        <p class="info-btn" @click="apply">Apply</p>
        <div class="info-item">
          <p class="item-title">Application fee: <span class="item-text">{{goodsInfo.apply_fee}}</span></p>
          <p class="item-title">Service fee: <span class="item-text">{{goodsInfo.service_fee}}</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api.js'
export default {
  name: 'detailGoods',
  props: {},
  data () {
    return {
      id: '',
      goodsInfo: {},
      universityName: [1],
      BasicyName: [1],
      ScholarshipName: [0],
      requirementsName: [0],
      documentsName: [0],
      structureName: [0],
      SpecialName: [0],
      schools: {},
      recommend: []
    }
  },
  components: {},
  computed: {},
  created () {
    this.id = this.$route.query.ProgramID ? this.$route.query.ProgramID : ''
    if (this.id <= 0) {
      return false
    }
    this.getGoodsInfo()
  },
  watch: {},
  methods: {
    comeBack () {
      this.$router.go(-1)
    },
    // 获取商品详情
    getGoodsInfo () {
      if (!this.id) {
        return false
      }
      let that = this
      let url = 'products/'+ that.id + '?include=recommend'
      Api.get({
        url: url,
        data: {},
        success (res) {
          if (res.data && Number(res.code) === 200) {
            that.goodsInfo = res.data ? res.data : {}
            that.schools = res.data.schools ? res.data.schools.data : {}
            that.recommend = res.data.recommend.data
          }
        }
      })
    },
    apply () {
      let id = this.goodsInfo.product_sn
      this.$router.push({
        path: '/apply',
        query: {
          program: id
        }
      })
    }
  }
}
</script>

<style scoped>
.content-item{margin-top:-10px;}
.content-item .item-info{background-color:#fff;border-radius:12px 12px 0 0;margin-bottom:10px;padding:15px 12px;position:relative;z-index:100;}
.content-item .item-info .info-title{font-size:18px;font-weight:500;color:#222;line-height:22px;max-height:44px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;word-break: break-all;margin-bottom:6px;}
.content-item .item-info .info-tag{margin-bottom:6px;color:#999;font-size:12px;line-height:16px;}
.content-item .item-info .info-tag .tag-city{margin-bottom:6px;}
.content-item .item-info .info-tag .tag-box .tag-self{padding:0 5px;border-right:1px solid #999;word-wrap:break-word;margin-bottom:6px;display:inline-block;}
.content-item .item-info .info-tag .tag-box .tag-self:first-child{padding-left:0;}
.content-item .item-info .info-tag .tag-box .tag-self:last-child{border-right:none;}
.content-item .item-info .info-text{font-size:12px;line-height:16px;font-weight:600;color:#999;}
.content-item .item-info .info-text .pay-text{font-size:18px;color:#FF5722;line-height:30px;margin-left:5px;}
.content-item .item-info .info-text .pay-past{font-size:14px;color:#999;line-height:30px;text-decoration: line-through;}
.content-item>>>.nut-collapse-item .collapse-wrapper .collapse-content{padding:0 16px 12;}
.content-item .item-collapse{margin-bottom:10px;}
.content-item .collapse-item{padding:0;}
.content-item .collapse-item.te{border:1px solid #eee;}
.content-item .collapse-item .item-list{overflow:hidden;padding:10px 0;color:#666;font-size:13px;}
.content-item .collapse-item .item-list.te{padding:10px 12px;}
.content-item .collapse-item .item-list.te:nth-child(2n -1){background-color:#eee;}
.content-item .collapse-item .item-list .list-text{float:right;}
.content-item .collapse-item .item-list .list-text.color{color:#FF5722;}
.content-item .collapse-item .item-title{text-align:center;background-color:#eee;color:#333;line-height:45px;}
.content-recommend{padding-top:10px;}
.content-recommend .recommend-title{text-align:center;font-size:14px;color:#FF5722;height:22px;font-weight:bold;position:relative;}
.content-recommend .recommend-title .title-text{position: absolute;line-height:22px;background-color:#f5f5f5;z-index:20;top:50%;left:50%;transform:translate(-50%,-50%);padding:0 5px;box-sizing:border-box;}
.content-recommend .recommend-title::after{content:'';position: absolute;display: block;width:350px;height:1px;background-color:#FF5722;top:50%;left:50%;transform:translate(-50%,-50%);}
.goods-item{margin:12px;padding-bottom:12px;}
.goods-footer{height:50px;}
.goods-footer .footer-info{position:fixed;left:0;bottom:0;right:0;background-color:#fff;width:100%;max-width:720px;margin:0 auto;overflow:hidden;padding:10px 12px;box-sizing:border-box;z-index:100;}
.goods-footer .footer-info .info-btn{float:right;width:120px;line-height:36px;color:#fff;background-color:#FF5722;border-radius:12px;text-align: center;font-size:16px;}
.goods-footer .footer-info .info-item{margin-right:130px;font-size:12px;color:#333;}
.goods-footer .footer-info .info-item .item-title{margin-bottom:6px;}
.goods-footer .footer-info .info-item .item-title .item-text{color:#ff5722;}
</style>
