<template>
  <div class="goods-index wrapper">
    <div class="goods-top">
      <div class="top-search">
        <i class="search-icon back" @click="comeBack"></i>
        <i class="search-icon filter" @click="show = true"></i>
        <div class="search-ipt">
          <nut-searchbar v-model="info.keyword" placeText="Program name / Program ID" :hasSearchButton="false" @submit="getList"></nut-searchbar>
        </div>
      </div>
    </div>
    <nut-popup v-model="show" lock-scroll round position="right" :style="{height: '100vh', width: '90%', 'box-sizing': 'border-box'}">
      <div class="popup-bos">
        <div class="popup-item">
          <div class="popup-list">
            <p class="list-title">Fields</p>
            <div class="list-box">
              <span class="list-select" :class="{'active':  Number(info.subject_id) === Number(0)}" @click="setInfoSubjectId(0)">All</span>
              <span class="list-select" :class="{'active': Number(info.subject_id) === Number(item.id)}" v-for="(item, index) in ProductsList" :key="index" @click="setInfoSubjectId(item.id)">{{item.en_name}}</span>
            </div>
          </div>
          <div class="popup-list">
            <p class="list-title">ISSP scholarship?</p>
            <div class="list-box">
              <span class="list-select" :class="{'active': Number(info.is_issp) === Number(item.id)}" v-for="(item, index) in isspList" :key="index" @click="setInfoIsspId(item)">{{item.name}}</span>
            </div>
          </div>
          <div class="popup-list">
            <p class="list-title">Degree</p>
            <div class="list-box">
              <span class="list-select" :class="{'active': Number(info.education_id) === Number(item.id)}" v-for="(item, index) in educationList" :key="index" @click="setInfoEducationId(item.id)">{{item.name}}</span>
            </div>
          </div>
          <div class="popup-list">
            <p class="list-title">Teaching Language</p>
            <div class="list-box">
              <span class="list-select" :class="{'active': Number(info.language_id) === Number(item.id)}" v-for="(item, index) in languageList" :key="index" @click="setLanguageId(item.id)">{{item.name}}</span>
            </div>
          </div>
          <div class="popup-list">
            <p class="list-title">Duration (Year)</p>
            <div class="list-box">
              <span class="list-select" :class="{'active':  Number(info.education_duration) === Number(0)}" @click="setEducationDuration(0)">All</span>
              <span class="list-select" :class="{'active': Number(info.education_duration) === Number(index + 1)}" v-for="(item, index) in lengthOfSchoolingList" :key="index" @click="setEducationDuration(Number(index) + 1)">{{item.name}}</span>
            </div>
          </div>
          <div class="popup-list">
            <p class="list-title">Intake</p>
            <div class="list-box">
              <span class="list-select" :class="{'active': Number(info.arrival_year) === Number(item.id)}" v-for="(item, index) in startYear" :key="index" @click="setArrivalYear(item.id)">{{item.name}}</span>
            </div>
            <div class="list-box">
              <span class="list-select" :class="{'active': Number(info.arrival_season) === Number(item.id)}" v-for="(item, index) in schoolSeason" :key="index" @click="setArrivalSeason(item.id)">{{item.name}}</span>
            </div>
          </div>
          <div class="popup-list">
            <p class="list-title">City</p>
            <div class="list-box">
              <span class="list-select" :class="{'active':  Number(info.city_id) === Number(0)}" @click="setCityId(0)">All</span>
              <span class="list-select" :class="{'active': Number(info.city_id) === Number(item.id)}" v-for="(item, index) in hotCityList" :key="index" @click="setCityId(item.id)">{{item.en_name}}</span>
            </div>
          </div>
          <div class="popup-list">
            <p class="list-title">Tuition fee (RMB/Year)</p>
            <div class="list-box">
              <input class="list-ipt" v-model="info.tuition_min" placeholder="" :disabled="false"/> -
              <input class="list-ipt" v-model="info.tuition_max" placeholder="" :disabled="false"/>
            </div>
          </div>
          <div class="popup-list">
            <p class="list-title">Age requirement (years old)</p>
            <div class="list-box">
              <input class="list-ipt" v-model="info.age_min" placeholder="" :disabled="false"/> -
              <input class="list-ipt" v-model="info.age_max" placeholder="" :disabled="false"/>
            </div>
          </div>
        </div>
        <div class="popup-boox">
          <div class="popup-btm">
            <div class="btm-btn" @click="reset">Reset</div>
            <div class="btm-btn search" @click="getList">Search</div>
          </div>
        </div>
      </div>
    </nut-popup>
    <div class="goods-item">
      <nut-infiniteloading @loadmore="onInfinite" :is-show-mod="true" :has-more="isHasMore" :is-loading="isLoading" loading-txt="Loading" unload-more-txt="no more information" :threshold="200">
        <div>
          <div class="goods-item-list" v-for="(item, index) in list" :key="index" @click="goPage(item)">
            <div class="list-img">
              <img class="img-self" :src="item.cover_image" />
              <span class="goods-issp" v-if="item.issp === 1">ISSP</span>
            </div>
            <div class="list-info">
              <p class="info-title">{{item.en_name}}</p>
              <p class="info-text"><span class="text-color">Tuition: {{item.is_self === 1 ? item.tuition_last_fee : item.self_tuition_fee}}</span>({{item.is_self === 1 ? item.tuition_last_fee_unit : item.self_tuition_fee_unit}})</p>
              <p class="info-text">City: {{item.city}} {{item.province}}</p>
              <p class="info-text">Degree: {{item.education}}</p>
              <p class="info-text">Teaching language: {{item.language}}</p>
              <p class="info-text"><span class="text-btn">Apply</span></p>
            </div>
          </div>
        </div>
      </nut-infiniteloading>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api.js'
export default {
  name: 'goodsList',
  props: {},
  data () {
    return {
      keyword: '',
      show: false,
      ProductsList: [],
      // 是否是issp
      isspList:[
        {
          name: 'All',
          id: 0
        },
        {
          name: 'Yes',
          id: 1
        },
        {
          name: 'No',
          id: 2
        }
      ],
      educationList: [],
      // 授课语言
      languageList: [],
      // 奖学金类型
      scholarshipList: [
        {
          name: 'All',
          id: 0
        },
        {
          name: 'Full scholarship',
          id: 1
        },
        {
          name: 'Partial scholarship',
          id: 2
        }
      ],
      // 学制
      lengthOfSchoolingList: [],
      // 开学年份时间
      startYear: [],
      // 开学季节
      schoolSeason: [],
      // 城市
      cityList: [],
      // 热门城市
      hotCityList: [],
      // 收搜条件
      info: {
        education_id: '0',
        language_id: '0',
        scholarship_id: '0',
        city_id: '0',
        arrival_season: '0',
        arrival_year: '0',
        education_duration: '0',
        is_issp: '0',
        tuition_min: '',
        tuition_max: '',
        age_min: '',
        age_max: '',
        subject_id: '0',
        keyword: ''
      },
      page: 1,
      list: [],
      productType: 1,
      limit: 6,
      total_pages: 0,
      isHasMore: true,
      isLoading: false
    }
  },
  components: {},
  computed: {},
  created () {
    this.productType = this.$route.params.type ? this.$route.params.type : 1
    this.getSubjects()
    this.languages()
    this.educations()
    this.cities()
    this.getHotCityList()
    this.educationDuration()
    this.intake()
    this.getYear()
    this.getProducts()
  },
  watch: {},
  methods: {
    comeBack () {
      this.$router.go(-1)
    },
    goPage (item) {
      this.$router.push({
        path: '/detail/goods',
        query: {
          program: item.urlname,
          ProgramID: item.product_sn
        }
      })
    },
    // 清空信息
    reset () {
      this.info = {
        education_id: '0',
        language_id: '0',
        scholarship_id: '0',
        city_id: '0',
        arrival_season: '0',
        arrival_year: '0',
        education_duration: '0',
        is_issp: '0',
        tuition_min: '',
        tuition_max: '',
        age_min: '',
        age_max: '',
        subject_id: '0',
        keyword: ''
      }
      this.show = false
    },
    // 获取商品列表
    getProducts () {
      let that = this
      this.isLoading = true
      let datas = {}
      datas = {
        ...this.info,
        limit: this.limit,
        page: this.page,
        type: that.productType
      }
      Api.get({
        url: 'products',
        data: datas,
        success (res) {
          if (res.code === 200) {
            if (res.data.length <= 0) {
              that.list = []
            }
            for (let i in res.data) {
              that.list.push(res.data[i])
            }
            that.show = that.show ? false : false
            that.isLoading = false
            that.total_pages = res.total_pages
          }
        }
      })
    },
    // 下拉加载
    onInfinite () {
      this.page++
      if (this.page <= this.total_pages) {
        this.getProducts()
      } else {
        this.isHasMore = false
        return false
      }
    },
    getList () {
      this.page = 1
      this.list = []
      this.getProducts()
    },
    getSubjects () {
      let that = this
      Api.get({
        url: 'subjects',
        success (res) {
          if (res.data) {
            that.ProductsList = res.data ? res.data : []
          }
        }
      })
    },
    // 获取语言
    languages () {
      let that = this
      Api.get({
        url: 'languages',
        data: {
          is_product_show: 1
        },
        success (res) {
          if (res.data && res.code === 200) {
            let list = [
              {
                id: 0,
                name: 'All'
              }
            ]
            for (let i in res.data) {
              list.push(res.data[i])
            }
            that.languageList = list
          }
        }
      })
    },
    // 获取educations
    educations () {
      let that = this
      Api.get({
        url: 'educations',
        success (res) {
          if (res.data && res.code === 200) {
            let list = [
              {
                id: 0,
                name: 'All'
              }
            ]
            for (let i in res.data) {
              list.push(res.data[i])
            }
            that.educationList = list
          }
        }
      })
    },
    // 获取城市
    cities () {
      let that = this
      Api.get({
        url:'cities',
        data: {
        },
        success (res) {
          if (res.data && res.code === 200) {
            for (let i in res.data) {
              that.cityList.push(res.data[i])
            }
          }
        }
      })
    },
    // 获取热门城市
    getHotCityList () {
      let that = this
      Api.get({
        url:'cities',
        data: {
          is_hot: 1
        },
        success (res) {
          if (res.data && res.code === 200) {
            for (let i in res.data) {
              that.hotCityList.push(res.data[i])
            }
          }
        }
      })
    },
    // 获取学制
    educationDuration () {
      let that = this
      Api.get({
        url: 'products/educationDuration',
        data: {},
        success (res) {
          if (res.code === 200 && res.data) {
            for (let i in res.data) {
              let data = {
                name: res.data[i] + ' year'
              }
              that.lengthOfSchoolingList.push(data)
            }
          }
        }
      })
    },
    // 获取入学季节
    intake () {
      let that = this
      Api.get({
        url: 'products/intake',
        data: {},
        success (res) {
          if (res.code === 200 && res.data) {
            that.schoolSeason = [
              {
                name: 'All',
                id: 0
              }
            ]
            for (let i in res.data) {
              let data = {
                name: res.data[i],
                id: Number(i) + 1
              }
              that.schoolSeason.push(data)
            }
          }
        }
      })
    },
    // 获取入学年份
    getYear () {
      let that = this
      Api.get({
        url: 'products/year',
        success (res) {
          if (res.data && res.code === 200) {
            that.startYear = [
              {
                name: 'All',
                id: 0
              }
            ]
            for (let i in res.data) {
              let data = {
                name: res.data[i],
                id: res.data[i]
              }
              that.startYear.push(data)
            }
          }
        }
      })
    },
    // 设置info SubjectId
    setInfoSubjectId (id) {
      this.info.subject_id = id
    },
    // 设置 是否是issp产品
    setInfoIsspId (info) {
      this.info.is_issp = info.id
    },
    // 设置学历
    setInfoEducationId (id) {
      this.info.education_id = id
    },
    // 设置授课语言
    setLanguageId (id) {
      this.info.language_id = id
    },
    // 设置学制
    setEducationDuration (id) {
      this.info.education_duration = id
    },
    //  选择入学季节
    setArrivalSeason (id) {
      this.info.arrival_season = id
    },
    // 选择入学年份
    setArrivalYear (id) {
      this.info.arrival_year = id
    },
    // 选择城市
    setCityId (id) {
      this.info.city_id = id
    }
  }
}
</script>

<style scoped>
.goods-top{background-color:#fff;padding:14px 24px;}
.goods-top .top-search{overflow:hidden;}
.goods-top .top-search .search-icon{display:block;width:24px;height:24px;float:left;background:url('~@/assets/images/come-back.png') center no-repeat;background-size:24px;margin-top:8px;}
.goods-top .top-search .search-icon.filter{float:right;background-image:url('~@/assets/images/list-filter.png');margin-left:10px;}


</style>
