import Vue from 'vue'
import Router from 'vue-router'
import index from '@/page/home/index'
import sign from '@/page/login/sign'
import password from '@/page/login/password'
import login from '@/page/login/index'
import personal from '@/page/certification/personal'
import company from '@/page/certification/company'
import goodsList from '@/page/list/goodsList'
import universitiesList from '@/page/list/universitiesList'
import goodsDetail from '@/page/detail/goods'
import schoolDetail from '@/page/detail/school'
import searchIndex from '@/page/search/index'
import applyIndex from '@/page/apply/index'
import orderIndex from '@/page/order/index'
import applicationsDetail from '@/page/applications/detail'

const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    // 默认打开页面
    {
      path: '/',
      name: 'home',
      component: index
    },
    // 首页
    {
      path: '/home',
      name: 'home',
      component: index
    },
    // 注册页面
    {
      path: '/signup',
      name: 'signup',
      component: sign
    },
    // 登录
    {
      path: '/login',
      name: 'login',
      component: login
    },
    // 重置密码
    {
      path: '/password',
      name: 'password',
      component: password
    },
    // 个人认证
    {
      path: '/certification/personal',
      name: 'personal',
      component: personal
    },
    // 代理商认证
    {
      path: '/certification/company',
      name: 'company',
      component: company
    },
    // 商品列表
    {
      path: '/list/goodsList',
      name: 'goodsList',
      component: goodsList
    },
    // 院校列表
    {
      path: '/list/universitiesList',
      name: 'universitiesList',
      component: universitiesList
    },
    // 商品详情
    {
      path: '/detail/goods',
      name: 'goodsDetail',
      component: goodsDetail
    },
    // 学校详情
    {
      path: '/detail/school',
      name: 'schoolDetail',
      component: schoolDetail
    },
    // 搜索
    {
      path: '/search/index',
      name: 'search',
      component: searchIndex
    },
    // 下单
    {
      path: '/apply',
      name: 'apply',
      component: applyIndex
    },
    // 订单列表
    {
      path: '/order',
      name: 'order',
      component: orderIndex
    },
    // 订单列表详情
    {
      path: '/applications/detail',
      name: 'applicationsDetail',
      component: applicationsDetail
    }
  ]
})
