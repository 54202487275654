<template>
  <div class="login-sign wrapper">
    <nut-navbar @on-click-back="back" :leftShow="true" :rightShow="false">Forgot password</nut-navbar>
    <div class="sign-box">
      <div class="public-item">
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Email</p>
          <div class="">
            <nut-textinput class="list-ipt" v-model="changeInfo.email" placeholder="Please enter your email" :clearBtn="true" :disabled="false" :has-border="false"/>
            <span class="list-btn" @click="getEmailCode()">{{codeText}}</span>
          </div>
          <p class="list-te">If you don't receive the  verification code, please check if the email is in your junk folder or spam list. You can also contact our consultant to get help. Email: <a href="mailto:eduprchinacom@gmail.com">eduprchinacom@gmail.com</a> WeChat: eduprchina002</p>
        </div>
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Verification code</p>
          <nut-textinput v-model="changeInfo.code" placeholder="Please enter Verification code" :clearBtn="true" :disabled="false" :has-border="false"/>
        </div>
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>New password</p>
          <nut-textinput  v-model="changeInfo.password" type="password" placeholder="Please enter new password" :clearBtn="true" :disabled="false" :has-border="false"/>
        </div>
        <div class="item-list">
          <p class="list-title"><span class="title-icon">*</span>Confirm password</p>
          <nut-textinput  v-model="changeInfo.password_confirmation" type="password" placeholder="Please er-enter you password" :clearBtn="true" :disabled="false" :has-border="false"/>
        </div>
      </div>
    </div>
    <div class="sign-bottom">
      <div class="bottom-box">
        <div class="bottom-btn" @click="passWord">Forgot password</div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api.js'
export default {
  name: 'loginSign',
  props: {},
  data () {
    return {
      codeText: 'Get Code',
      checked: true,
      disabled: false,
      showVerification: false,
      countryCode: '',
      changeInfo: {
        email: '',
        code: '',
        password: '',
        password_confirmation: ''
      },
    }
  },
  components: {},
  computed: {},
  created () {
    document.title = "EDUPRCHINA"
  },
  watch: {},
  mounted () {
  },
  methods: {
    back () {
      this.$router.go(-1)
    },
    goHome () {
      this.$router.push({path: '/'})
    },
    // 获取验证码
    getEmailCode () {
      if (this.changeInfo.email === '') {
        this.$toast.warn('Please enter in email')
        return false
      }
      if (this.changeInfo.email.indexOf('@') < 0) {
        this.$toast.warn("Email format error!")
        return false
      }
      let time = 60
      let that = this
      Api.post({
        url: 'randcode',
        data: {
          email: this.changeInfo.email
        },
        success (res) {
          if (res.data.code === 200 && res.data) {
            that.$toast.success(res.data.msg)
            let timer = setInterval( function () {
              time--
              that.codeText = time + "s"
              that.disabled = true
              if ( time === 0 ) {
                clearInterval(timer)
                that.codeText = "Get Code"
                that.disabled = false
              }
            }, 1000)
          } else {
            that.$toast.warn(res.data.msg)
          }
        }
      })
    },
    // 重置密码验证
    passWordVerify () {
      if (this.changeInfo.email === '') {
        this.$toast.warn("Email can't be empty. Please enter it.")
        return false
      }
      if (this.changeInfo.code === '') {
        this.$toast.warn("Verification code can't be empty. Please enter it.")
        return false
      }
      if (this.changeInfo.password === '') {
        this.$toast.warn("Password can't be empty. Please enter it.")
        return false
      }
      if (this.changeInfo.password !== this.changeInfo.password_confirmation) {
        this.$toast.warn("Password and Confirm password are different. Please re-enter.")
        return false
      }
      return true
    },
    passWord () {
      let that = this
      if (!this.passWordVerify()) {
        return false
      }
      Api.post({
        url: 'password',
        data: this.changeInfo,
        success (res) {
          if (res.data && res.code === 200) {
            that.$router.go(-1)
            that.$toast.success(res.data.msg)
          }
        }
      })
    },
    goLogin () {
      this.$router.push({name: 'login'})
    }
  }
}
</script>

<style scoped>
.login-sign>>>.nut-checkbox input:checked{background-color:#7c53c9;border-color:#7c53c9;}
.sign-box{background-color:#fff;padding:0 10px;box-sizing:border-box;}
.sign-bottom{height:150px;}
.box-checkbox{padding-top:15px;margin:0 auto;width:280px;}
.sign-bottom .bottom-box{position:fixed;bottom:0;height:130px;max-width:750px;width:100%;margin:0 auto;background-color:#fff;}
.sign-bottom .bottom-box .bottom-btn{margin:10px 20px;height:45px;line-height:45px;text-align:center;background-color:#7c53c9;color:#fff;border-radius:10px;}
.list-te{font-size:12px;}
.sign-bottom .bottom-text{text-align: center;margin-bottom:20px;color: #564363;font-size:14px;}
</style>
