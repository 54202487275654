<template>
  <div class="footer-index wrapper">
    <div class="footer-box">
      <img class="footer-title" src="~@/assets/images/footer-title.png">
      <div class="footer-info">
        <div class="info-text">
          <p class="text-self">Contact Us</p>
          <p class="text-self">Address: Floor 9, Block A5, Xingzhihui Business Garden, No.19, Xinghuo Road, High-Tech Zone, Nanjing, P.R.China</p>
          <p class="text-self">Tel: +86-25-58745604 (EXT. 8002)</p>
          <p class="text-self">Email: service@eduprchina.com</p>
          <p class="text-self">Email: info@eduprchina.com</p>
        </div>
        <div class="footer-img">
          <img class="img-self" src="~@/assets/images/footer-qr-code.png" />
          <p class="img-text">SCAN VIA WECHAT TO CONTACT US</p>
        </div>
      </div>
      <p class="footer-text">© 2022 EDUPRCHINA. All rights reserved.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterIndex',
  props: {},
  data () {
    return {
    }
  },
  components: {},
  computed: {},
  created () {},
  watch: {},
  methods: {}
}
</script>

<style scoped>
.footer-box{background-color:#e7e6e6;padding:30px 28px 15px;margin-top:40px;box-sizing:border-box;position:relative;}
.footer-index .footer-title{display: block;position: absolute;top:-12px;left:50%;transform:translate(-50%,0);width:172px;}
.footer-index .footer-info{overflow:hidden;padding-bottom:10px;border-bottom:1px solid #333;}
.footer-index .footer-info .info-text{float:left;width:50%;color:#333;font-size:12px;}
.footer-index .footer-info .info-text .text-self{margin-bottom:10px;}
.footer-index .footer-text{font-size:12px;margin-top:20px;}
.footer-index .footer-info .footer-img{float:left;width:50%;}
.footer-index .footer-info .footer-img .img-self{display: block;width:80px;height:80px;margin:0 auto 10px;}
.footer-index .footer-info .footer-img .img-text{font-size:12px;color:#333;text-align: center;}
</style>
