import { render, staticRenderFns } from "./bestSeller.vue?vue&type=template&id=32e4a270&scoped=true&"
import script from "./bestSeller.vue?vue&type=script&lang=js&"
export * from "./bestSeller.vue?vue&type=script&lang=js&"
import style0 from "./bestSeller.vue?vue&type=style&index=0&id=32e4a270&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32e4a270",
  null
  
)

export default component.exports