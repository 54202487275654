<template>
  <div class="applications-detail wrapper">
    <div class="detail-box">
      <div class="public-item">
        <div class="item-list" v-for="(item, index) in fileList" :key="index">
          <p class="list-title"><span class="title-icon">*</span>{{item.en_name}} (Picture format is: jpeg/png/gif/bmp)</p>
          <span class="upload-box" v-for="(info, idx) in overList" :key="idx">
            <i class="upload-icon" v-if="Number(info.application_attachment_id) === Number(item.id)" size="10px" @click="imgDelete(idx)"></i>
            <img class="upload-img" v-if="Number(info.application_attachment_id) === Number(item.id)" :src="info.full_url" />
          </span>
          <nut-uploader
            name="file"
            :url="'https://center.istudyedu.com/api/orders/upload?application_attachment_id='+ item.id"
            :acceptType = "['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/jpg']"
            :headers="importHeaders"
            @success="onSuccess"
            @failure="permitFail"
            typeError="Sorry, uploading this type of file is not supported!"
            limitError="Sorry, the file size exceeds the limit!"
            xmlError="Sorry, Your browser does not support this component!">
            <span class="upload-btn">+</span>
          </nut-uploader>
        </div>
      </div>
    </div>
    <div class="footer-btn">
      <p class="btn-self" @click="saveMaterial">Submit</p>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api.js'
export default {
  name: 'applicationsDetail',
  props: {},
  data () {
    return {
      id: 0,
      importHeaders: {
        Authorization: 'bearer ' + window.localStorage.getItem('token')
      },
      overList: [],
      fileList: [],
      audit_state: 0,
    }
  },
  components: {},
  computed: {},
  created () {
    this.id = this.$route.query.id ? this.$route.query.id : 0
    if (this.id > 0) {
      this.materials()
    }
  },
  watch: {},
  methods: {
    materials () {
      this.overList = []
      let that = this
      Api.post({
        url: 'applications/materials',
        data: {
          application_id: that.id
        },
        success (res) {
          if (res.code === 200 && res.data) {
            that.fileList = res.data
            for (let i in res.data) {
              if (Number(res.data[i].audit_state) === 2) {
                that.audit_state = 3
              }
              if (res.data[i].paths.length > 0) {
                for (let j in res.data[i].paths) {
                  that.overList.push(res.data[i].paths[j])
                }
              }
            }
          }
        }
      })
    },
    // 上传文件
    onSuccess (e, res) {
      let data = JSON.parse(res)
      if (data.code === 200 && data.data) {
        let datas = {
          application_attachment_id: data.data.application_attachment_id,
          document_id: data.data.document_id,
          url: data.data.url,
          name: data.data.name,
          full_url: data.data.full_url
        }
        this.overList.push(datas)
      }
    },
    // 删除上传文件
    imgDelete (index) {
      this.overList.splice(index, 1)
    },
    // 文件上传提交
    saveMaterial () {
      let that = this
      Api.post({
        url: 'orders/saveMaterial',
        data: {
         materials: that.overList,
         application_id: that.id,
         audit_state: that.audit_state
        },
        success (res) {
          if (res.code === 200 && res.data) {
            that.$toast.success(res.data.msg)
            that.$router.go(-1)
          }
        }
      })
    },
    logoPreview (e) {
      console.log(e)
    },
    permitFail () {
      this.$toast.fail('upload failed')
    },
  }
}
</script>

<style scoped>
.applications-detail{background-color:#fff;}
.applications-detail .detail-box{padding:0 10px;}
.nut-uploader{overflow:hidden;}
.upload-box{position:relative;float:left;}
.upload-icon{display:block;position:absolute;top:0;right:5px;width:20px;height:20px;background:url('~@/assets/images/icon-close.png') center no-repeat;background-size:20px;}
.footer-btn{height:45px;margin-top:10px;}
.footer-btn .btn-self{position:fixed;bottom:0;width:100%;max-width:720px;margin:0 auto;text-align: center;line-height:45px;background-color:#7c53c9;color:#fff;font-size:14px;}
</style>
