<template>
  <div class="homt-index wrapper">
    <div class="index-top">
      <div class="top-logo">
        <p class="user-name" v-if="userName !== ''" @click="goLogin">{{userName}}, welcome! </p>
        <div class="logo-user" v-else @click="goLogin">
          <span class="user-text">Log in</span>
        </div>
        <img class="logo-img" src="~@/assets/images/index-logo.png" />
      </div>
      <div @click="goSearch">
        <p class="top-search">Program name / Program ID / City</p>
        <!-- <nut-textinput class="top-search" v-model="search" :has-border="false" :clearBtnPersonnal="true" placeholder="Program name / Program ID / City" :disabled="true" ></nut-textinput> -->
      </div>
      <!-- <nut-tab @tab-switch="tabSwitch" :is-scroll="true" :is-show-line="false" :line-width="20" :def-index="0">
        <nut-tab-panel v-for="(item, index) in tabList" :key="index" :tab-title="item.text"></nut-tab-panel>
      </nut-tab> -->
      <section class="theme-list">
       <div class="fixed-nav" ref="fixednav">
         <div class="fixed-nav-content">
           <p v-for="(item, index) in tabList" :key="index" :class="['tab-title', activeId === index && 'select-tab']" @click="changeTab(index, $event)">{{ item.text }}</p>
         </div>
       </div>
     </section>
    </div>
    <div class="index-centent">
      <bestSeller v-if="activeId === 0" :bannerList="bannerList"></bestSeller>
      <highSuccess v-if="activeId === 1" @onInfinite="onInfinite" :isLoading.sync="isLoading" :isHasMore.sync="isHasMore" :list="goodsList" :adspaces="adspaces"></highSuccess>
      <fullScholarship v-if="activeId === 2" @onInfinite="onInfinite" :isLoading.sync="isLoading" :isHasMore.sync="isHasMore" :list="goodsList" :adspaces="adspaces"></fullScholarship>
      <chineseLanguage v-if="activeId === 3" @onInfinite="onInfinite" :isLoading.sync="isLoading" :isHasMore.sync="isHasMore" :list="goodsList" :adspaces="adspaces" :bannerList="bannerList"></chineseLanguage>
      <mbbsIndex v-if="activeId === 4" @onInfinite="onInfinite" :isLoading.sync="isLoading" :isHasMore.sync="isHasMore" :list="goodsList" :adspaces="adspaces" :bannerList="bannerList"></mbbsIndex>
      <news v-if="activeId === 5" @onInfinite="onInfinite" :isLoading.sync="isLoading" :isHasMore.sync="isHasMore" :list="goodsList"></news>
    </div>
    <footerIndex></footerIndex>
    <footerNav :type="'home'"></footerNav>
  </div>
</template>

<script>
import Api from '@/api/api.js'
import footerIndex from "@/components/public/footer.vue"
import bestSeller from "@/components/home/bestSeller.vue"
import highSuccess from "@/components/home/highSuccess.vue"
import fullScholarship from "@/components/home/fullScholarship.vue"
import chineseLanguage from "@/components/home/language.vue"
import mbbsIndex from "@/components/home/mbbsIdnex.vue"
import news from "@/components/home/news.vue"
import footerNav from "@/components/public/footerNav.vue"

export default {
  name: 'homeIndex',
  props: {},
  data () {
    return {
      search: 'Program name / Program ID / City',
      tabList: [
        {
          text: 'Best Seller',
          title: 'best_seller',
          id: 0
        },{
          text: 'High Success',
          title: 'high_success',
          id: 0
        },{
          text: 'Full Scholarships',
          title: 'full_scholarship',
          id: 0
        },{
          text: 'Chinese Language',
          title: 'language',
          id: 0
        },{
          text: 'MBBS',
          title: 'mbbs',
          id: 0
        },{
          text: 'New',
          title: 'new',
          id: 0
        }
      ],
      activeId: 0,
      userName: '',
      goodsList: [],
      adspaces: {},
      bannerList: [],
      isHasMore: true,
      isLoading: false,
      tabId: 0,
      page: 1,
      total_pages: 0
    }
  },
  components: {
    bestSeller,
    footerIndex,
    highSuccess,
    fullScholarship,
    chineseLanguage,
    mbbsIndex,
    news,
    footerNav
  },
  computed: {},
  created () {
    document.title = "EDUPRCHINA"
    this.userName = JSON.parse(window.localStorage.getItem('user_info')) ? JSON.parse(window.localStorage.getItem('user_info')).username : ''
    this.setting()
  },
  watch: {},
  methods: {
    submitFun () {
      this.hint()
    },
    setting () {
      let that = this
      Api.get({
        url: 'homes/setting',
        success (res) {
          if (res.code === 200 && res.data) {
            for (let i in that.tabList) {
              that.tabList[i].id = Number(res.data[that.tabList[i].title])
              if (that.tabList[i].title === 'best_seller') {
                that.getBanners(Number(res.data[that.tabList[i].title]))
              }
            }
          }
        }
      })
    },
    changeTab (id, event) {
      // 如果选择的和当前激活的不同
      if (id !== this.activeId) {
        this.activeId = Number(id)
        this.goodsList = []
        this.page = 1
        // 计算当前按钮的位置，看是否需要移动
        const spanLeft = event.clientX; // 当前点击的元素左边距离
        const divBox = document.querySelector(".select-tab").clientWidth / 2 // 点击的元素一半宽度
        const totalWidths = document.body.clientWidth // 屏幕总宽度
        const widths = totalWidths / 2 // 一半的屏幕宽度
        const spanRight = totalWidths - spanLeft; // 元素的右边距离
        const scrollBox = document.querySelector(".fixed-nav") // 获取最外层的元素
        const scrollL = scrollBox.scrollLeft // 滚动条滚动的距离
        // 当元素左边距离 或者 右边距离小于100时进行滑动
        if (spanRight < 100 || spanLeft < 100) {
          scrollBox.scrollLeft = scrollL + (spanLeft - widths) + divBox;
        }
        this.tabId = this.tabList[this.activeId].id
        this.getGoodsList(this.tabId)
        this.getAdspaces(this.tabId)
        this.getBanners(this.tabId)
      }
    },
    goLogin () {
      this.$router.push({ path: '/login' })
    },
    goSearch () {
      console.log(12313)
      this.$router.push({ name: 'search' })
    },
    // 获取商品列表
    getGoodsList (id) {
      let that = this
      this.isLoading = true
      Api.get({
        url: 'products',
        data: {
          label_id: id,
          page: this.page,
          limit: 10
        },
        success (res) {
          if (res.data && res.code === 200) {
            for (let i in res.data) {
              that.goodsList.push(res.data[i])
            }
            that.isLoading = false
            that.total_pages = res.total_pages
          }
        }
      })
    },
    // 获取广告位
    getAdspaces (id) {
      let that = this
      that.adspaces = {}
      Api.get({
        url: 'adspaces',
        data: {
          label_id:id
        },
        success: function (res) {
          if (res.code === 200) {
            that.adspaces = Object.keys(res.data).length > 0 ? res.data : {}
          } else {
            that.adspaces = {}
          }
        }
      })
    },
    // 获取banner
    getBanners (id) {
      let that = this
      that.bannerList = []
      Api.get({
        url: 'banners',
        data: {
          label_id: id,
          is_app: 1
        },
        success: function (res) {
          if (res.data) {
            let data = res.data
            for (let i in data) {
              that.bannerList.push(data[i])
            }
          }
        }
      })
    },
    hint () {
      this.$toast.warn('The mobile site is under maintenance, please open EDUPRCHINA website on the computer to use. If any questions, please contact your consultant or send email to service@eduprchina.com!')
    },
    // 无限加载
    onInfinite () {
      this.page++
      if (this.page <= this.total_pages) {
        this.getGoodsList(this.tabId)
      } else {
        this.isHasMore = false
        return false
      }
    }
  }
}
</script>

<style scoped>
.index-top>>>.nut-tab{padding:0;}
.index-top>>>.nut-tab{padding:0;border:none;}
.index-top>>>.nut-tab-active a{color:#7f51cb;}
.index-top>>>.nav-bar{background-color:#7f51cb;}
.index-top>>>.nut-tab{background:#f5f5f5;}
.index-top>>>.nut-title-nav-scroll{background:#f5f5f5;}
.index-top>>>.nut-tab-title{border-bottom:0;}
.index-top>>>.nut-title-nav-scroll{min-width:120px;width:auto;}
.index-top>>>.nut-tab-link{text-align:center;font-size:14px;}
.index-top>>>.nut-tab-item{height:0;padding:0;border:0;}
.index-top>>>.nut-textinput-disabled input{background-color:#d8d6db;border-radius:20px;color:#999;}
.index-top>>>.nut-searchbar .search-input input{background-color:#eee7fb;}
.index-top{margin:0 20px;}
.index-top .top-logo{position:relative;}
.index-top .logo-img{display:block;width:205px;margin:18px auto;}
.index-top .logo-user{position:absolute;left:10px;top:5px;width:80px;}
.index-top .logo-user .user-text{display:inline-block;font-size:12px;color:#7f51cb;border: 1px solid #7f51cb;padding:2px 8px;}
/* .index-top .user-icon{display:inline-block;width:20px;height:20px;background:url('~@/assets/images/index-user.png') center no-repeat;background-size:20px;} */
.user-name{font-size:14px;padding-top:5px;color:#7f51cb;}
.top-search{margin-bottom:15px;background-color:#d8d6db;color:#999;line-height:40px;font-size:14px;padding:0 20px;border-radius:40px;}
.home-index .nav-tab-wrap{width:100%;white-space:nowrap;}
.home-index .nav-tab-wrap .nav-tab{position:relative;}
.home-index .nav-tab-wrap .nav-tab .nav-tab-item{margin-right:60rpx;line-height:80rpx;display:inline-block;color:#333333;font-size:30rpx;}
.home-index .nav-tab-wrap .nav-tab .nav-tab-item:nth-of-type(1){margin-left:60rpx;}
.home-index .nav-tab-wrap .nav-tab .nav-tab-item.active{font-weight:bold;}
.home-index .nav-tab-wrap .nav-tab .underline{position:absolute;width:40rpx;height:8rpx;border-radius:8rpx;background-color:#3d4977;left:20rpx;bottom:0;transition:0.4s;}
.home-index .home-main .home-content{min-height:100vh;}
.theme-list {margin-top:12px;}
.fixed-nav{overflow-x:scroll;-webkit-overflow-scrolling:touch;font-size:14px;}
.fixed-nav-content{display:flex;}
.tab-title{padding:0 13px 12px;margin-right:12px;color: #141414;border-radius:13px;font-size:14px;flex-shrink:0;height:1.52rem;line-height:1.52rem;}
.tab-title.select-tab{position:relative;color:#7f51cb;}
.tab-title.select-tab::before{position:absolute;bottom:0px;left:50%;content:'';display: block;width:20px;height:4px;background-color:#7f51cb;transform:translate(-50%,0);border-radius:4px;}
::-webkit-scrollbar{width: 0.01rem;opacity: 0; display: none;}
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track{background-color: #fff;opacity: 0;}
/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb{width: 0.01rem;border-radius: 0.01rem;opacity: 0;}
.index-centent{padding:20px;}
</style>
